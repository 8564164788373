import React, { useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { deleteCoverImage, uploadCoverImage } from '../../../helpers/api';
import { useToastNotifications } from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import Splash from '../../Splash';

interface Props {
  sportsCenterId: number;
  coverImage: SportsCenterGallery | null;
  refresh: () => any;
}

const getFileFormat = (file: File) => {
  if (file.name.endsWith('.png')) {
    return 'PNG';
  } else if (file.name.endsWith('.jpg') || file.name.endsWith('.jpeg')) {
    return 'JPEG';
  } else {
    return null;
  }
}

const CoverImage: React.FC<Props> = ({ sportsCenterId, coverImage, refresh }) => {
  const { newToastNotification } = useToastNotifications();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const doUpload = (element: HTMLInputElement) => {
    const file = element.files && element.files[0];
    if (!file) {
      return;
    }

    const format = getFileFormat(file);
    if (!format) {
      setErrorMsg(translate('unsupportedFormat'));
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    uploadCoverImage(sportsCenterId, formData)
        .then(() => {
          setLoading(false);
          newToastNotification(translate('uploadCoverImage'), translate('operationSuccessful') + '.');
          refresh();
        })
        .catch(({ response: { data } }) => {
          setLoading(false);
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('unexpectedError') + '.');
          }
        })
  }


  const doDelete = () => {
    setLoading(true);
    deleteCoverImage(sportsCenterId)
        .then(() => {
          setLoading(false);
          newToastNotification(translate('deleteCoverImage'), translate('operationSuccessful') + '.');
          refresh();
        })
        .catch(({ response: { data } }) => {
          setLoading(false);
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('unexpectedError') + '.');
          }
        })
  }

  if (loading) {
    return <Splash />;
  }

  return <>
    {errorMsg && <Alert variant='danger' className='mt-4' style={{ fontSize: '0.85rem' }}>
      <div style={{ fontWeight: '700' }}>{translate('unexpectedError')}.</div>
      {errorMsg}
    </Alert>}
    <Row className='mt-4'>
      <Col>
        <h3>{translate('coverImage')}</h3>
      </Col>
    </Row>
    <Row className='my-4'>
      <Col className='w-100 d-flex justify-content-center'>
        {!coverImage && <div className='text-muted'>{translate('noCoverImage')}</div>}
        {coverImage && <img src={coverImage.url} alt={coverImage.id} style={{ maxHeight: 400, maxWidth: 400 }} />}
      </Col>
    </Row>
    {coverImage && <Row>
      <Col className='w-100 d-flex justify-content-center'>
        <Button variant='danger' onClick={doDelete}>{translate('delete')}</Button>
      </Col>
    </Row>}
    {!coverImage && <Row className='mt-4'>
      <Col>
        <Form>
          <Form.Group>
            <Form.Label>{translate('uploadCoverImage')}</Form.Label>
            <Form.Control type='file' onChange={e => doUpload(e.target as HTMLInputElement)} />
          </Form.Group>
        </Form>
      </Col>
    </Row>}
  </>
}

export default CoverImage;